#root {
    min-height: 100%;
}

/* #root > div {
    min-height: 100%;
} */

html,
body {
    height: 100%;
}

html {
    height: -webkit-fill-available;
}

body {
    background-color: #121212 !important;
    overflow-x: hidden;
}

header {
    width: 100vw !important;
    background-color: #121212 !important;
}

.MuiPaper-root {
    background-color: #1e1f21 !important;
}

.MuiToolbar-root {
    background-color: #121212 !important;
}

.MuiAppBar-root > .MuiToolbar-root {
    background-color: #080808 !important;
}

.MuiInputBase-multiline {
    padding: 10px 10px !important;
    background: white;
    border-radius: 3px;
    color: black !important;
}

.sc-bdnxRM.knGxRj {
    background: white;
    border-radius: 3px;
    color: black !important;
}

.MuiFormLabel-root.Mui-focused {
    color: unset !important;
}

.MuiTableCell-stickyHeader {
    background-color: unset !important;
    border-bottom: unset !important;
}

.MuiBadge-badge {
    background-color: #a41e22 !important;
}

.MuiTextField-root {
    width: 100%;
    border-radius: 5px;
}

.MuiTextField-root > .MuiInputBase-root {
    color: black !important;
    background-color: white !important;
}

.searchInput > .MuiInputBase-root {
    color: white !important;
    background-color: #121212 !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
    color: white !important;
}

.MuiButton-root {
    text-transform: unset !important;
}

.MuiSelect-icon {
    color: black !important;
}

.MuiSelect-root {
    background-color: white !important;
    color: black !important;
}

.MuiSnackbarContent-root {
    background-color: inherit !important;
    color: white !important;
}

.MuiRadio-root {
    color: white !important;
}

.register_useragreement > a {
    color: white;
}

.tertiary {
    background-color: #54b01d !important;
    color: white !important;
}

.success {
    background-color: #54b01d !important;
}

.error {
    background-color: #a41e22 !important;
}

.pink {
    background-color: #fa1b55 !important;
    color: white !important;
}

.pink-font {
    color: #fa1b55 !important;
}

.white {
    background-color: white !important;
    color: #a41e22 !important;
}

.MuiLinearProgress-barColorPrimary {
    background-color: #a41e22 !important;
}

.blue {
    background-color: #4860f1 !important;
    color: white !important;
}

.purple {
    background-color: #cf10ff !important;
    color: white !important;
}

.gender-female {
    background-color: #a31d21 !important;
    color: white !important;
}
.gender-male {
    background-color: #f05a79 !important;
    color: white !important;
}
.gender-transgen {
    background-color: #cf10ff !important;
    color: white !important;
}
.gender-all {
    background-color: #93193a !important;
    color: white !important;
}

.text-bold {
    font-weight: bold;
}
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}
.text-left {
    text-align: left;
}

.label-description {
    color: grey;
    font-size: smaller !important;
}

.small-font {
    font-size: small !important;
}

.smaller-font {
    font-size: smaller !important;
}

.gray-font {
    color: grey;
}

.container-with-border {
    padding: 10px;
    border: solid 0.5px;
    border-radius: 10px;
}

.btn-info {
    color: white !important;
    background-color: #4860f1 !important;
}

.wordWrap {
    white-space: pre-wrap;
    /* CSS3 */
    white-space: -moz-pre-wrap;
    /* Firefox */
    white-space: -pre-wrap;
    /* Opera 7 */
    white-space: -o-pre-wrap;
    /* Opera 7 */
    word-wrap: break-word;
    /* IE */
    /* word-break: break-all; */
}

::placeholder {
    color: #333333;
}

.chat-div {
    margin: unset !important;
}

.right {
    text-align: end;
}

.right-container {
    background-color: #f4f4f4;
    color: black;
    font-size: larger;
    padding: 5px;
    border-radius: 10px 0px 10px 10px !important;
}

/* chat box  */

.chat-room {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}

.left {
    text-align: start;
}

.left-container {
    background-color: #fa1b55;
    font-size: larger;
    padding: 5px;
    border-radius: 0px 10px 10px 10px !important;
}

.time {
    color: #54575c;
    text-align: end;
}

.chat-message {
    white-space: pre-line;
    margin: unset !important;
    text-align: start;
    display: flex;
    word-break: break-all;
}

.chat-text-div {
    /* position: absolute;
    bottom: 0; */
    width: 100% !important;
    background-color: #252525 !important;
    align-items: flex-end;
}

.chat-text-div-desktop {
    position: fixed;
    bottom: 0;
    background-color: #252525 !important;
    align-items: flex-end;
    width: 50% !important;
}
.chat-full-text-div {
    position: fixed;
    bottom: 0;
    width: 75%;
    background-color: #252525 !important;
    align-items: flex-end;
}

.chat-text {
    background-color: #252525 !important;
}

.chat-text > .MuiInputBase-multiline {
    background-color: #252525 !important;
}

.chat-text textarea {
    background-color: #252525 !important;
    color: white !important;
    padding: 10px;
    font-size: larger;
}

.chat-text textarea::placeholder {
    color: white !important;
}

.MuiInput-underline:before {
    border-bottom: unset !important;
}

.MuiInput-underline:after {
    border-bottom: unset !important;
}

.MuiBottomNavigationAction-root {
    min-width: unset !important;
}

/* .MuiTableCell-stickyHeader {
    background-color: #080808 !important;
} */

input.select-dropdown {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.link {
    text-decoration: underline;
}

.arrow {
    position: fixed;
    bottom: 5px;
    left: 50%;
    transform: translate(-50%, -50%);
}

.arrow2 {
    position: fixed;
    bottom: 0px;
    z-index: 1300;
    left: 50%;
    transform: translate(-50%, -50%);
}
.arrow2 button {
    background-color: #252525;
}

.arrowTop {
    position: absolute;
    top: 15px;
    left: 50%;
    transform: translate(-50%, -50%);
}
.arrowTop button {
    background-color: #252525;
}

.arrow span {
    display: block;
    width: 30px;
    height: 30px;
    border-bottom: 5px solid white;
    border-right: 5px solid white;
    transform: rotate(225deg);
    margin: -10px;
    animation: scroll 2s infinite;
}

.arrow span:nth-child(2) {
    animation-delay: -0.2s;
}

.arrow span:nth-child(3) {
    animation-delay: -0.4s;
}

.emoji-picker,
.emoji-mart {
    width: 100% !important;
}
.emoji-mart-preview {
    display: none;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #252525;
    border-radius: 10px;
}

@keyframes scroll {
    0% {
        opacity: 0;
        transform: rotate(225deg) translate(-20px, -20px);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: rotate(225deg) translate(20px, 20px);
    }
}

.close-button {
    position: absolute !important;
    background: black !important;
    color: white !important;
    top: 3px !important;
    right: 3px !important;
    border-radius: 50% !important;
    opacity: 0.7 !important;

    font-size: 1.25rem !important;
}

.preview-button {
    position: absolute;
    background: black !important;
    color: white;
    top: 3px;
    left: 3px;
    font-size: 1.25rem !important;
    border-radius: 50%;
    opacity: 0.7;
}

.close-button-vid {
    position: absolute;
    background: black;
    color: white;
    top: 0px;
    right: -12px;
    border-radius: 50%;
    opacity: 0.7;
    z-index: 100;
}

.center-div-1 {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -70px;
    margin-left: -70px;
    width: 150px;
    height: 100px;
}
.center-div-2 {
    position: absolute;
    top: 25%;
    /* left: 50%; */
    /* margin-top: -70px;
    margin-left: -70px; */
    width: 100%;
    height: 100px;
    text-align: center;
}

.top-up-desc {
    text-align: center;
    background-color: white;
    margin: auto;
    height: fit-content;
    width: 100%;
    padding: 2em;
    border-radius: 5px;
}

.captcha-div > div > div {
    display: flex;
    place-content: center;
}

/* The Modal (background) */

.modal {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 5000;
    /* Sit on top */
    padding-top: 100px;
    /* Location of the box */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.9);
    /* Black w/ opacity */
}

/* Modal Content (image) */

.modal-content {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
}

.fixed-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
}
.p-10 {
    padding: 10px;
}

@-webkit-keyframes zoom {
    from {
        -webkit-transform: scale(0);
    }
    to {
        -webkit-transform: scale(1);
    }
}

@keyframes zoom {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}

/* The Close Button */
.white-font {
    color: white;
}

.red-font {
    color: #a41e22 !important;
}

.close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}

.carousel {
    height: 88vh;
}
.carousel-profile {
    height: 88vh;
}

.CarouselItem {
    height: 88vh;
}

.CarouselItem > div {
    height: 88vh;
}

.MuiDataGrid-root {
    border: unset !important;
}

.MuiDataGrid-columnHeaderTitle,
.MuiDataGrid-cell {
    white-space: normal !important;
    word-wrap: break-word !important;
    text-overflow: unset !important;
}

.MuiDataGrid-columnHeaderTitleContainer,
.MuiDataGrid-columnHeader,
.MuiDataGrid-cell {
    padding: unset !important;
}

.MuiDataGrid-cell {
    line-height: unset !important;
}

.MuiDataGrid-columnsContainer {
    line-height: unset !important;
    align-content: flex-start;
}

.MuiDataGrid-columnSeparator {
    display: none !important;
}

.MuiBadge-badge {
    border-radius: 10px !important;
    background-color: red !important;
    padding: 6px !important;
}

#video-agora-local {
    display: flex;
    z-index: -1;
    position: fixed;
    top: 65px;
    right: 5px;
    border: 2px solid black;
    height: 150px;
    width: 130px;
}

.video-agora-box {
    display: flex;
    z-index: -1;
    position: fixed;
    top: 60px;
    height: 100vh;
    width: 100vw;
}

a {
    color: white;
    text-decoration: underline;
}

.videoWrapper {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    height: 0;
}

.videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.quickstart-guide-div {
    border-radius: 10px;
    background-color: #a41e22;
    color: white;
    text-align: center;
    padding: 10px;
    vertical-align: middle;
}

.profileBox {
    height: 25vh;
    position: relative;
    font-size: 10px;
    /* padding-bottom: 100%; */
}

/* 100% Image Width on Smaller Screens */

.profile-image {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

#custom-modal {
    max-width: 600px;
}
#home {
    margin-top: unset;
}
.home {
    height: calc(100vh - 56px) !important;
    overflow-y: auto;
}

.home {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
}
.home::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}
.chat-room-desktop {
    position: unset;
}

.profile-image-contain {
    object-fit: contain;
    width: 80%;
    height: 80%;
    margin: 10%;
}

@media only screen and (max-device-width: 480px) {
    #home {
        margin-top: 60px;
    }
    #custom-modal {
        width: 80%;
    }
    .profile-image {
        object-fit: cover;
    }

    .modal-content {
        width: 100%;
    }
    .profileBox {
        height: 18vh !important;
        position: relative !important;
        font-size: 10px !important;
    }

    .carousel {
        height: 91vh;
    }
    .carousel-profile {
        height: 100%;
    }

    .CarouselItem {
        height: 100%;
    }

    .CarouselItem > div {
        height: 100%;
    }
}

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.5;
    cursor: pointer;
}

.videocam {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    opacity: 0.8;
    justify-content: center;
    align-items: center;
}

.MuiListSubheader-root {
    line-height: unset !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.header-logo {
    width: 11em;
    height: 2.25em;
    vertical-align: middle;
    padding-left: 5px;
}

.incognito-label {
    text-align: center;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    color: gray;
    display: none;
}

.disclaimer-icon {
    height: 60px;
    /* position: fixed; */
    right: 20px;
    top: 10px;
}

.helper {
    line-height: 1.3;
    color: #000 !important;
    font-size: large !important;
}

.mask {
    opacity: 0.65;
    color: rgb(31, 31, 31);
}

.react-joyride__spotlight {
    border: solid 2px #fa1b55;
}

.tutorial-logo {
    position: fixed;
    right: 43%;
    top: -20px;
    background: white;
    border-radius: 50%;
    padding: 8px;
    box-shadow: 1px 1px 1px 1px lightgrey;
}
/* #___reactour{
    pointer-events: ;
} */

.react-joyride__tooltip button {
    outline: none;
}

.overflow-hidden {
    overflow: hidden;
}

.react-joyride__tooltip {
    width: 260px !important;
}
.custom-accordion {
    background-color: #121212 !important;
}
.gradient-background-css {
    min-height: 40px;
    /* background: linear-gradient(360deg, rgb(37 37 37) 0%, rgb(37 37 37 / 20%) 40%); */
    /*  linear-gradient(360deg, rgb(0 0 0) 0%, rgb(103 103 103 / 80%) 80%); 
    linear-gradient(360deg, rgb(0 0 0) 0%, rgb(97 97 97 / 80%) 50%)
    linear-gradient(360deg, rgb(0 0 0) 0%, rgb(222 222 222 / 80%) 80%);
    linear-gradient(1turn,rgb(0 0 0),rgb(103 103 103 / 80%) 80%);  */
}

.gray-background {
    background: rgb(37 37 37 / 25%) !important;
}

.shadow-text {
    text-shadow: 1px 1px #000000;
}
.emoji {
    text-shadow: none !important;
}
.gradient-background {
    /* background: linear-gradient(360deg, rgb(0 0 0) 0%, rgb(103 103 103 / 80%) 80%); 
    /* linear-gradient(360deg, rgb(0 0 0) 0%, rgb(97 97 97 / 80%) 50%) */
    /*linear-gradient(360deg, rgb(0 0 0) 0%, rgb(222 222 222 / 80%) 80%); */

    /* linear-gradient(1turn,rgb(0 0 0),rgb(103 103 103 / 80%) 80%);  */

    background-image: url("../assets/images/overlay.webp");
    background-size: 100% 100%;
    background-position: center;
    padding-top: 20px !important;
}

.gradient-background-top {
    /* background: linear-gradient(180deg, rgb(0 0 0) 0%, rgb(103 103 103 / 80%) 80%);  */
    background-image: url("../assets/images/overlay-reverse.webp");
    background-size: 100% 100%;
    background-position: center;
    padding-bottom: 10px !important;
}

.shadow-orb {
    filter: drop-shadow(1px 1px 1px rgb(0 0 0 / 1));
}
.headline-font {
    display: flex;
    font-size: 12px !important;
    white-space: pre-wrap;
    /* CSS3 */
    white-space: -moz-pre-wrap;
    /* Firefox */
    white-space: -pre-wrap;
    /* Opera 7 */
    white-space: -o-pre-wrap;
    /* Opera 7 */
    word-wrap: break-word;
    /* IE */
    /* word-break: break-all; */
}

.ellipse {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: auto;
    /* padding: 24px 12px 64px; */
    max-width: 100%;
    overflow-y: auto;
}

.item {
    display: flex;
    padding: 8px;
    position: relative;
    max-width: 33.33%;
    flex-basis: 100%;
    border-radius: 2px;
    flex-direction: column;
}
.inner-item > img {
    height: 100%;
    width: 100%;
    object-fit: fill !important;
}

.inner-item {
    height: 130px;
    display: flex;
    max-width: 100%;
    max-height: 100%;
    align-items: center;
    flex-direction: row;
    position: relative;
    justify-content: center;
}

.SortableList {
    position: relative;
    z-index: 0;
    background-color: #f3f3f3;
    border: 1px solid #efefef;
    border-radius: 3px;
    outline: none;

    width: 100%;
    height: auto;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border: 1px solid #e0e0e0;
    list-style: none;
    padding: 0;
}
.SortableItem {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: 60px;
    padding: 0 20px;
    background-color: #fff;
    border-bottom: 1px solid #efefef;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #333;
    font-weight: 500;
}
.SortableHelper {
    box-shadow: rgba(0, 0, 0, 0.075) 0px 1px 6px, rgba(0, 0, 0, 0.075) 0px 1px 4px;
    background-color: rgba(255, 255, 255, 0.8);
    cursor: row-resize;
}

.see-around-button {
    position: absolute !important;
    width: 60vw !important;
    bottom: 110px;
    right: 20vw;
    text-align: center;
}

.drag-button {
    position: absolute !important;
    width: 40vw !important;
    top: 47vh;
    right: 30vw;
    text-align: center;
}

.map-instruction {
    position: absolute !important;
    width: 80vw !important;
    top: 22vh;
    left: 10vw;
    text-align: center;
    color: black;
    background-color: white;
    padding: 8px;
    text-align: left;
}

.sticky-div {
    background-color: #1e1f21 !important;
    position: fixed;
    top: 55px;
    width: 100%;
    z-index: 100;
    min-height: 56px;
}
.center-vertical {
    display: flex;
    align-items: center;
}

.back-button {
    position: absolute;
    left: 15px;
}

.blur {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
    width: 100%;
    height: 100%;
    background-color: #ccc;
}

.default-image {
    margin: 15%;
    object-fit: cover;
    height: 70%;
    width: 70%;
    position: absolute;
}

.infinite-scroll-component {
    overflow: unset !important;
}

.underline {
    text-decoration: underline;
}

.version {
    text-align: center;
    font-size: xx-small;
    position: absolute;
    bottom: 0;
    left: 45%;
    opacity: 0.3;
}

.top-spacing {
    margin-top: 10px !important;
}

.App-alert {
    position: absolute;
    top: 0;
    background-color: red;
}
.alert {
    width: 20rem;
    background: #fff;
    padding: 1rem;
    border-radius: 3px;
    height: 100px;
}

.MuiAccordion-root {
    background: #121212 !important;
}
.sub-accordion {
    padding: 0 10px;
}

.menu-padding {
    padding: 0px 10px;
}

.padding-unset {
    padding: unset !important;
}

.lazy-load-image-background {
    display: unset !important;
}

.overlap-pulse {
    z-index: 1;
    position: absolute;
    margin-top: 6px;
    margin-left: 2px;
    width: 71px;
    height: 22px;
    border-radius: 2px;
}

.pulse {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
    animation-direction: alternate;
    -webkit-animation-name: pulse;
    animation: pulse 2s infinite;
}

/* @keyframes pulse {
    to {
        box-shadow: 0 0 10px 5px transparent, 0 0 10px 10px rgba(232, 45, 76, 0.349);
    }
} */

@keyframes pulse {
    50% {
        box-shadow: 0 0 0 0.1em #e82d4c, 0 0 0 0.3em rgba(244, 132, 124, 0.75);
    }
    100% {
        box-shadow: 0 0 0 0.3em #e82d4c, 0 0 0 0.6em rgba(244, 132, 124, 0);
    }
}
/* @-webkit-keyframes pulse {
    0% {
        -webkit-ttransform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }
    70% {
        -webkit-transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
    100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
} */
