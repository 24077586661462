@font-face {
    font-family: "NeulisAlt-Bold";
    src: url("../assets/font/NeulisAlt-Bold.otf");
}

@font-face {
    font-family: "NeulisAlt-Regular";
    src: url("../assets/font/NeulisAlt-Regular.otf");
}

@media only screen and (min-width: 520px) {
    .u-row {
        width: 500px !important;
    }

    .u-row .u-col {
        vertical-align: top;
    }

    .u-row .u-col-100 {
        width: 500px !important;
    }
}

@media (max-width: 520px) {
    .u-row-container {
        max-width: 100% !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .u-row .u-col {
        min-width: 320px !important;
        max-width: 100% !important;
        display: block !important;
    }

    .u-row {
        width: calc(100% - 40px) !important;
    }

    .u-col {
        width: 100% !important;
    }

    .u-col > div {
        margin: 0 auto;
    }
}

body {
    margin: 0;
    padding: 0;
}

table,
tr,
td {
    vertical-align: top;
    border-collapse: collapse;
}

p {
    margin: 0;
}

.ie-container table,
.mso-container table {
    table-layout: fixed;
}

* {
    line-height: inherit;
}

a[x-apple-data-detectors="true"] {
    color: inherit !important;
    text-decoration: none !important;
}

@media (min-width: 481px) and (max-width: 768px) {
}

table,
td {
    color: #000000;
}

.table1 {
    font-family: "NeulisAlt-Regular";
}

.table2 {
    border-collapse: collapse;
    table-layout: fixed;
    border-spacing: 0;
    mso-table-lspace: 0pt;
    mso-table-rspace: 0pt;
    vertical-align: top;
    border-top: 1px solid #bbbbbb;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}
.table3 {
    border-collapse: collapse;
    table-layout: fixed;
    border-spacing: 0;
    mso-table-lspace: 0pt;
    mso-table-rspace: 0pt;
    vertical-align: top;
    min-width: 320px;
    margin: 0 auto;
    background-color: #e7e7e7;
    width: 100%;
}
.table4 {
    width: 500px;
}
.td1 {
    overflow-wrap: break-word;
    word-break: break-word;
    padding: 10px;
    font-family: "NeulisAlt-Regular";
}

.td2 {
    word-break: break-word;
    border-collapse: collapse !important;
    vertical-align: top;
}

.td4 {
    background-color: #e7e7e7;
}

.td5 {
    padding: 0px;
    background-color: #242524;
}
.td6 {
    padding-right: 0px;
    padding-left: 0px;
}
.td7 {
    word-break: break-word;
    border-collapse: collapse !important;
    vertical-align: top;
    font-size: 0px;
    line-height: 0px;
    mso-line-height-rule: exactly;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

.td8 {
    overflow-wrap: break-word;
    word-break: break-word;
    padding: 30px 10px;
    font-family: "NeulisAlt-Regular";
}
.image1 {
    border-collapse: collapse;
    table-layout: fixed;
    border-spacing: 0;
    mso-table-lspace: 0pt;
    mso-table-rspace: 0pt;
    vertical-align: top;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    object-fit: fill;
    height: 100%;
}

.div1 {
    padding: 0px;
    background-color: #242524;
}

.div2 {
    margin: 0 auto;
    min-width: 320px;
    max-width: 500px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    background-color: transparent;
}

.div3 {
    border-collapse: collapse;
    display: table;
    width: 100%;
    height: 100%;
    background-color: transparent;
}
.div4 {
    max-width: 320px;
    min-width: 500px;
    display: table-cell;
    vertical-align: top;
}
.div5 {
    background-color: #242524;
    height: 100%;
    width: 100% !important;
}
.div6 {
    padding: 0px 0px 50px;
    border-top: 0px solid transparent;
    border-left: 0px solid transparent;
    border-right: 0px solid transparent;
    border-bottom: 0px solid transparent;
}
.div7 {
    color: #ffffff;
    line-height: 140%;
    text-align: center;
    word-wrap: break-word;
}

.tr1 {
    background-color: transparent;
}

.tr2 {
    vertical-align: top;
}
.tr3 {
    vertical-align: top;
}
.td6 {
    background-color: #242524;
    width: 500px;
    padding: 0px 0px 0px;
    border-top: 0px solid transparent;
    border-left: 0px solid transparent;
    border-right: 0px solid transparent;
    border-bottom: 0px solid transparent;
}

.h1 {
    margin: 0px;
    color: #ffffff;
    line-height: 140%;
    text-align: center;
    word-wrap: break-word;
    font-weight: normal;
    font-family: "NeulisAlt-Bold";
    font-size: 26px;
}

.h3 {
    margin: 0px;
    color: #ffffff;
    line-height: 140%;
    text-align: center;
    word-wrap: break-word;
    font-weight: normal;
    font-family: "NeulisAlt-Regular";
    font-size: 18px;
}

.p1 {
    font-size: 14px;
    line-height: 140%;
}

.p2 {
    font-family: "NeulisAlt-Regular" !important;
    font-size: 18px;
    line-height: 25.2px;
}

.wrap {
    overflow: hidden;
}

.carousel {
    display: flex;
    left: -100%;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    transform: translateX(100%);
}
@media (min-width: 30em) {
    .carousel {
        left: -33.33333%;
        transform: translateX(33.33333%);
    }
}
@media (min-width: 40em) {
    .carousel {
        left: -20%;
        transform: translateX(20%);
    }
}

.carousel.is-reversing {
    transform: translateX(-100%);
}
@media (min-width: 30em) {
    .carousel.is-reversing {
        transform: translateX(-33.33333%);
    }
}
@media (min-width: 40em) {
    .carousel.is-reversing {
        transform: translateX(-20%);
    }
}

.carousel.is-set {
    transform: none;
    transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}

.carousel-seat {
    background: #ddd;
    flex: 1 0 100%;
    order: 2;
}
.carousel-seat:nth-child(even) {
    background: #d5d5d5;
}
@media (min-width: 30em) {
    .carousel-seat {
        flex-basis: 33.33333%;
    }
}
@media (min-width: 40em) {
    .carousel-seat {
        flex-basis: 20%;
    }
}

.carousel-seat.is-ref {
    order: 1;
}